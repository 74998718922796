/**
 * LM-LIFF-01-1 綁定流程-確認門號-否，改用別的門號
 * https://lmliff.cht.com.tw/signed/new-number/apply
 */
import { useCallback, useMemo, useState } from 'react';
import { navigate } from 'gatsby';

import styled from 'styled-components';

import { useGetUserInfo } from '@/apis/hooks';
import CircleStepper from '@/components/CircleStepper';
import ConfirmAlert from '@/components/ConfirmAlert';
import PrimaryInput from '@/components/PrimaryInput';
import StandardButton from '@/components/StandardButton';
import { regexMobileWithDash } from '@/utils/regex';

const ContentWrapper = styled.div`
  padding: 0 16px 12px 16px;
`;

const CircleStepperBox = styled.div`
  margin-top: 40px;
`;

const Description = styled.div`
  margin-top: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #111111;
`;

const InputBox = styled.div`
  width: 100%;
  margin-top: 69px;
`;

const Button = styled(StandardButton)`
  margin-top: 16px;
  margin-bottom: 40px;
`;

const NewNumberApplyPage = () => {
  const { userInfo } = useGetUserInfo();
  /** 目前的手機門號 */
  const currentMobile = useMemo(
    () => userInfo.mobile && userInfo.mobile,
    [userInfo.mobile]
  );
  const [mobile, setMobile] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = useCallback(() => {
    navigate(-1);
  }, []);

  const onChange = useCallback((value) => {
    setMobile(value);
  }, []);

  const isMobileValid = useMemo(
    () => mobile && regexMobileWithDash.test(mobile),
    [mobile]
  );

  /** 驗證是否與原本門號相同 */
  const checkReLinkMobile = useCallback(() => {
    if (mobile.replace(/-|\+/g, '') === currentMobile) {
      setIsModalOpen(true);
      return;
    }
    navigate(isMobileValid ? '/signed/new-number/validate' : '', {
      state: { mobile: mobile.replace(/-|\+/g, '') },
    });
  }, [mobile, currentMobile, isMobileValid]);

  return (
    <ContentWrapper>
      <CircleStepperBox>
        <CircleStepper totalSteps={2} currentStep={1} />
      </CircleStepperBox>
      <Description>請輸入手機門號</Description>
      <InputBox>
        <PrimaryInput
          id="input-phone-number"
          allowClear
          useDash
          validationType="mobile"
          onChange={onChange}
          isErrorEnable={false}
          onValidate={() => {}}
          large
        />
      </InputBox>
      <Button
        type="primary"
        disabled={!isMobileValid}
        onClick={checkReLinkMobile}
      >
        下一步
      </Button>
      <ConfirmAlert
        titleText="與原綁定門號重複"
        description="請重新確認綁定門號"
        buttonText="確定"
        show={isModalOpen}
        onConfirm={handleModalClose}
      />
    </ContentWrapper>
  );
};

export default NewNumberApplyPage;
